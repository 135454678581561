import React from "react";
import estilos from './styles/company.module.css'
import infraestructura from './assets/infraestructura.png'
import posiciones from './assets/posiciones.png'
import monitoreo from './assets/monitoreo.png'
import movilidad from './assets/movilidad.png'
import espacios from './assets/espacios.png'
import innovacion from './assets/innovacion.png'
import admin from './assets/admin.png'


export default function Company() {

    return (
        <div className={estilos.container}>
            <div>
                <br />
                <div>
                    <h1 className={estilos.text}>Empresa</h1>
                </div>
                <div className={estilos.text_left}>
                    <img src={infraestructura} alt="infrastructure" height="150px" width="150px" style={{ margin: "20px" }} />
                    <p><b>Infraestructura Actual de Nuestro Contact Center</b>
                        <br />
                        Nos enorgullece presentar la infraestructura avanzada de nuestro Contact Center, diseñada para brindar servicios de primer nivel en el área comercial. Nuestra sólida estructura se compone de diversas herramientas y recursos que aseguran un desempeño óptimo y una experiencia excepcional para nuestros clientes.</p>
                </div>
                <div className={estilos.text_right}>
                    <p><b>Posiciones Comerciales Tecnológicamente Avanzadas</b>
                        <br />
                        Contamos con un total de 100 posiciones comerciales, equipadas con la última tecnología en equipos de computación, telefonía y conectividad. Esto garantiza un flujo eficiente de comunicaciones, permitiéndonos atender las necesidades de nuestros clientes de manera ágil y efectiva.</p>
                    <img src={posiciones} alt="Positions" height="150px" width="150px" style={{ margin: "20px" }} />
                </div>
                <div className={estilos.text_left}>
                    <img src={monitoreo} alt="Monitoreo" height="150px" width="150px" style={{ margin: "20px" }} />
                    <p><b>Monitoreo en Tiempo Real y Comunicación Interactiva</b>
                        <br />
                        Nuestra capacidad de monitoreo de llamadas en tiempo real y la posibilidad de interactuar con nuestros operadores a través de plataformas y chat en línea nos brindan un nivel de control y agilidad sin precedentes. Esto nos permite ofrecer una respuesta personalizada y satisfactoria a cada consulta o requerimiento.</p>
                </div>
                <div className={estilos.text_right}>
                    <p><b>Movilidad Eficiente con Flota Propia</b>
                        <br />
                        Para garantizar la puntualidad y eficiencia del personal, contamos con una flota propia de transporte que facilita el traslado en zonas de alto rendimiento. Esto nos permite cumplir con los más exigentes estándares de atención al cliente, sin comprometer la calidad del servicio.</p>
                    <img src={movilidad} alt="Movilidad" height="150px" width="150px" style={{ margin: "20px" }} />
                </div>
                <div className={estilos.text_left}>
                    <img src={espacios} alt="Spaces" height="150px" width="150px" style={{ margin: "20px" }} />
                    <p><b>Espacios Versátiles para Reuniones con Clientes</b>
                        <br />
                        Nuestra empresa se enorgullece de ofrecer una sala de reuniones especialmente diseñada para recibir a nuestros clientes. Aquí, podemos resolver operaciones de manera presencial y brindar un servicio personalizado que fortalece nuestra relación con ellos.</p>
                </div>
                <div className={estilos.text_right}>
                    <p><b>Innovación en Comunicación con Prospectos y Clientes</b>
                        <br />
                        Dentro de nuestras estrategias de comunicación, nos destacamos por ofrecer soluciones no tradicionales para contactar a prospectos y clientes. Nuestras campañas de mensajes SMS, WhatsApp, correo electrónico y otras formas de comunicación innovadoras, nos permiten mantener una relación cercana con nuestro público objetivo.</p>
                    <img src={innovacion} alt="Innovation" height="150px" width="150px" style={{ margin: "20px" }} />
                </div>
                <div className={estilos.text_left}>
                    <img src={admin} alt="Admin" height="150px" width="150px" style={{ margin: "20px" }} />
                    <p><b>Equipo Administrativo Altamente Eficiente</b>
                        <br />
                        Nuestro equipo administrativo está compuesto por 40 empleados altamente capacitados y comprometidos con la excelencia. Su ubicación presencial en la empresa asegura una coordinación y fluidez en las operaciones, lo que se traduce en una garantía de eficiencia y calidad en nuestro servicio.</p>
                </div>
                {/* <div>
                    <p>En resumen, nuestra empresa ha logrado establecer una infraestructura de Contact Center sólida y altamente avanzada. Estamos comprometidos con la excelencia en la atención al cliente y la búsqueda constante de nuevas formas de mejorar y enriquecer nuestra oferta de servicios. Confiamos en que esta descripción de nuestra infraestructura refleje nuestra dedicación a la profesionalidad y al servicio excepcional que brindamos a nuestros clientes.</p>
                </div> */}
            </div>
            <br />
        </div>
    )
}