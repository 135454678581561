import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import 'swiper/css'
import portada from '../assets/Noticia 1/Portada.jpeg'
import image_1 from '../assets/Noticia 1/image_1.jpeg'
import image_2 from '../assets/Noticia 1/image_2.jpeg'
import image_3 from '../assets/Noticia 1/image_3.jpeg'
import image_4 from '../assets/Noticia 1/image_4.jpeg'
import image_5 from '../assets//Noticia 1/image_5.jpeg'
import image_6 from '../assets/Noticia 1/image_6.jpeg'
import image_7 from '../assets/Noticia 1/image_7.jpeg'
import image_8 from '../assets/Noticia 1/image_8.jpeg'
import s from '../styles/news.module.css'

export default function Noticia_1() {
    return (
        <div className={s.container_2}>
            <br />
            <br />
            <h1 className={s.tittle}>Exitosa jornada de trabajo en equipo: Empleados y alumnos se unen en actividades recreativas.</h1>
            <p className={s.text}>En un emocionante esfuerzo por fomentar la integración, el trabajo en equipo y el bienestar, The Best Connection S.A.S tuvo el placer de recibir a alumnos del Instituto de Educación Física N° 9-016 "Dr. Jorge E. Coll" en una jornada repleta de actividades recreativas. La iniciativa tenía como objetivo no solo promover la actividad física y el cuidado de la salud, sino también fortalecer las habilidades de trabajo en equipo y colaboración en un ambiente ameno y lúdico.</p>
            <div>
                <Swiper
                modules={[Autoplay, Pagination]}
                autoplay={{
                    delay:3000,
                    disableOnInteraction: false
                }}>
                <SwiperSlide><img className={s.images} src={portada} alt="foto grupal" width='40%' height='40%'/></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_1} alt="imagen 1" width="40%" height="40%" /></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_2} alt="imagen 2" width="40%" height="40%" /></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_3} alt="imagen 3" width="40%" height="40%" /></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_4} alt="imagen 4" width="40%" height="40%" /></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_5} alt="imagen 5" width="40%" height="40%" /></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_6} alt="imagen 6" width="40%" height="40%" /></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_7} alt="imagen 7" width="40%" height="40%" /></SwiperSlide>
                <SwiperSlide><img className={s.images} src={image_8} alt="imagen 8" width="40%" height="40%" /></SwiperSlide>
                </Swiper>
            </div>
            <div>
            <p className={s.text}>El evento, que tuvo lugar el pasado 19 de octubre de 2023, se llevó a cabo en la Plaza Pedro del Castillo y contó con la participación de empleados y estudiantes por igual. La jornada de actividades recreativas entre nuestra empresa y los alumnos del instituto de educación física no solo promovió el ejercicio físico y la diversión, sino que también reforzó la importancia de trabajar en equipo y colaborar de manera efectiva.</p>
            <p className={s.text}>Esta iniciativa, que se enmarca en nuestro compromiso con la formación y el bienestar de nuestro equipo, demostró ser un rotundo éxito. </p>
            <br />
            
            </div>
        </div>
    )
}