import React from "react";
import footerImg from './assets/TBC-footer.png'
import s from './styles/footer.module.css'

export default function Footer() {
    return (
        <div className={s.container}>
        <div className={s.img}>
            <img src={footerImg} alt="footerImg" height="100px" width="100px" className={s.footerImg} />
        </div>
        <div className={s.horarios}>
            <h5>Horarios de atención:</h5>
            <p>Lunes a viernes 9hs a 21hs <br />Sabados 9hs a 15hs</p>
        </div>
        <div className={s.contacto}>
            <h5>Contacto:</h5>
            <p>Videla Castillo 2105 <br /> Oficina 1 <br /> Ciudad de Mendoza</p>
            <p>thebestconnection@thebestconnection.com.ar</p>
        </div>
    </div>
    )
}