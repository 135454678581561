import React from "react";
import { NavLink } from "react-router-dom";
import portada from '../assets/Noticia 1/Portada.jpeg'
import s from '../styles/news.module.css'


export default function News() {
    return (
        <div className={s.container}>
            <br />
            <br />
            <h1>Novedades</h1>
            <div className={s.div}>
                <NavLink to='/noticia 1'>
                    <h2>Exitosa jornada de trabajo en equipo: Empleados y alumnos <br /> se unen en actividades recreativas.</h2>
                    <img src={portada} alt="Portada"/>
                </NavLink>
            </div>
        </div>
    )
}