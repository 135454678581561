import React from "react";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import 'swiper/css'
import staff from './assets/staff.jpg'
import staff2 from './assets/staff2.jpg'
import staff3 from './assets/staff3.jpg'
import staff4 from './assets/staff4.jpg'
import staff5 from './assets/staff5.jpg'
import s from './styles/home.module.css'


import home from './assets/home 5.png'



export default function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <div className={s.container}>
            <div className={s.img_container}>
                <img className={s.img} src={home} alt="img1" />
            </div>
                <div className={s.plan_1}>
                    <h1>Misión</h1>
                    <p>Facilitar conexiones significativas y eficientes mediante un servicio de atención al cliente excepcional. Nuestra misión es ofrecer soluciones personalizadas y rápidas para satisfacer las necesidades de nuestros clientes, brindando una experiencia positiva en cada interacción.</p>
                </div>
                <div className={s.plan_2}>
                    <h1>Visión</h1>
                    <p>Ser reconocidos como el call center líder en la industria, destacándonos por nuestro compromiso con la excelencia, la innovación tecnológica y la empatía en el trato con nuestros clientes. Aspiramos a ser el socio preferido de las empresas en la gestión de sus relaciones con los clientes.</p>
                </div>
                <div className={s.plan_3}>
                    <h1>Valores</h1>
                    <ul>
                        <li>Excelencia</li>
                        <li>Empatía y respeto</li>
                        <li>Innovación y tecnología</li>
                        <li>Trabajo en equipo</li>
                        <li>Integridad y transparencia</li>
                        <li>Responsabilidad social</li>
                        <li>Adaptabilidad y agilidad</li>
                        <li>Crecimiento y aprendizaje</li>
                    </ul>
                </div>
               <div className={s.div5}>
                <Swiper
                    modules={[Autoplay, Pagination]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}>
                    <SwiperSlide><img className={s.carrusel} src={staff} alt="staff1" height="500px" width="750px" /></SwiperSlide>
                    <SwiperSlide><img className={s.carrusel} src={staff2} alt="staff2" height="500px" width="750px" /></SwiperSlide>
                    <SwiperSlide><img className={s.carrusel} src={staff3} alt="staff3" height="500px" width="750px" /></SwiperSlide>
                    <SwiperSlide><img className={s.carrusel} src={staff4} alt="staff4" height="500px" width="750px" /></SwiperSlide>
                    <SwiperSlide><img className={s.carrusel} src={staff5} alt="staff5" height="500px" width="750px" /></SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}