import React from "react";
import { useEffect } from "react";
import emailjs from "@emailjs/browser"
import s from './styles/hiring.module.css'

export default function Hiring() {

    const sendMail = (e) => {
        e.preventDefault()
        emailjs.sendForm('service_69okxii', 'template_jz6bmwb', e.target, 'LTu3EiNdFKqsrz59l')
            .then(response => console.log(response), alert('Mensaje enviado correctamente'), e.target.reset())
            .catch(error => console.log(error))
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (

        <div className={s.container}>
            <div className={s.div1}>
                <h1>Trabajá con nosotros</h1>
            </div>
            <div className={s.div2}>
                <form className={s.form} onSubmit={sendMail}>
                    <label>Correo electrónico: </label>
                    <br />
                    <input className={s.input} type="email" name="email" required />
                    <br />
                    <label>Asunto</label>
                    <br />
                    <input className={s.input} type="text" name="asunto" />
                    <br />
                    <label>Nombre completo: </label>
                    <br />
                    <input className={s.input} type="text" name="nombre" required />
                    <br />
                    <label>Número de teléfono</label>
                    <br />
                    <input className={s.input} type="tel" name="celular" />
                    <br />
                    <label>URL del CV en Google Drive, Dropbox, OneDrive</label>
                    <br />
                    <input className={s.input} type="url" name="CV" />
                    <br />
                    <button className={s.button} type="submit">Enviar</button>
                </form>
            </div>
        </div>
    )
}
