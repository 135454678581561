import React, {useEffect} from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { NavLink } from "react-router-dom";
import tbc from './assets/TBC-navbar.png'
import s from './styles/navbar.module.css'


export default function Navbar() {

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <div className={s.container}>
            <div className={s.img}>
            <NavLink to='/'><img src={tbc} alt="tbc" height="50px" /></NavLink>
            </div>
            <div className={s.links}>
            <NavLink className={s.link} to='/'>Inicio</NavLink>
            <NavLink className={s.link} to='/company'>Empresa</NavLink>
            <NavLink className={s.link} to='/about'>Nosotros</NavLink>
            <NavLink className={s.link} to='/services'>Servicios</NavLink>
            <NavLink className={s.link} to='/news'>Novedades</NavLink>
            <NavLink className={s.link} to='/hiring'>Trabajá con nosotros</NavLink>
            </div>
        </div>
    )
}