import React from "react";
import atp from './assets/atp.png'
import id from './assets/I+D.png'
import bigdata from './assets/big data.png'
import call from './assets/call hogar.png'
import estilos from './styles/services.module.css'


export default function Services() {

    return (
        <div className={estilos.container}>
            <div className={estilos.tittle}>
                <h1>Servicios</h1>
                <span>Creemos en el potencial de la gente y apostamos a la formación de equipos de trabajo capaces de innovar y generar soluciones mediante el desarrollo de sus propios talentos y de una constante búsqueda de mejores formas de hacer.</span>
            </div>
            <div className={estilos.text}>
                <div>
                    <img src={atp} alt="ATP" height="100px" width="100px" />
                    <p>Ofrecemos atención al cliente a través de diversos canales, garantizando la excelencia en cada etapa del proceso y la interacción con nuestros usuarios.</p>
                </div>
                <div>
                    <img src={id} alt="I+D" height="100px" width="100px" />
                    <p>Potenciamos y aumentamos la productividad y competitividad a través de la investigación y el desarrollo tecnológico.</p>
                </div>
                <div>
                    <img src={bigdata} alt="BIGDATA" height="100px" width="100px" />
                    <p>Gestionamos grandes volúmenes de datos para generar información con posibilidades ilimitadas, desde identificar nuevas oportunidades de negocios y clientes potenciales hasta optimizar procesos operativos.</p>
                </div>
                <div>
                    <img src={call} alt="CALL" height="100px" width="100px" />
                    <p>Asesoramiento y servicios de venta de portabilidad numérica y fibra óptica.</p>
                </div>
                <br />
            </div>
        </div>
    )
}