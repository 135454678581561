import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import Hiring from './components/Hiring';
import About from './components/About';
import Footer from './components/Footer';
import Company from './components/Company';
import Services from './components/Services';
import News from './components/News/news';
import Noticia_1 from './components/News/Noticia_1';
import Navbar from './components/Navbar';


function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Navbar/>
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />}  />
      <Route path='/hiring' element={<Hiring />} />
      <Route path='/company' element={<Company/>} />
      <Route path='/services' element={<Services />} />
      <Route path='/news' element={<News/>} />
      <Route path='/noticia 1' element={<Noticia_1/>} />
    </Routes>
    <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;