import React from "react";
import { useEffect } from "react";
import 'swiper/css';
import Aos from "aos";
import "aos/dist/aos.css"
import s from './styles/about.module.css'


export default function About() {

    useEffect(() => {
        window.scrollTo(0, 0);
        Aos.init({ duration: 2000 })
    }, [])

    return (
        <div className={s.container}>
            <div className={s.div1}>
                <h1>Quienes somos</h1>
            </div>
            <div className={s.div2}>
                <p>The Best Connection S.A.S es una empresa vanguardista especializada en la comercialización de servicios B2C & B2B. Nuestra fundación se inspira en el liderazgo ejemplar de una mujer al frente, cuya visión promueve el crecimiento y desarrollo de la industria con una perspectiva de género.</p>
                <p>Creemos firmemente en el impacto positivo de la diversidad en los procesos de innovación, creatividad, toma de decisiones y construcción de consensos. Reconocemos su relevancia en el desarrollo del sector empresarial y la economía en general. Por esta razón, fomentamos activamente iniciativas que propicien la participación de mujeres y diversidad de géneros en nuestra industria.</p>
                <p>Hemos marcado una pauta significativa en el mercado de empresas BPO, destacando la importancia de incluir a la mujer en la gestión de pequeñas, medianas y grandes empresas. A lo largo de estos años, hemos llevado a cabo mejoras de procesos e ideas innovadoras que nos han permitido sobresalir.</p>
                <p>Nuestra especialización radica en desarrollar perfiles altamente adecuados a los requerimientos de nuestros clientes. Contamos con un equipo experimentado en la comercialización y administración de ventas, posicionándonos como la primera empresa en la región en ofrecer soluciones personalizadas de gestión integral de ventas.</p>
                <p>The Best Connection S.A.S posee una amplia trayectoria en la comercialización de servicios de telecomunicaciones. Contamos con una estructura de ventas sólida, compuesta por jefes de equipos, personal de back office, comerciales, calidad, administración y área de cobranzas.</p>
                <p>Nuestra premisa es brindar una atención al cliente excepcional a través de diversos canales, asegurando calidad en cada proceso y una comunicación efectiva con los usuarios. Para lograrlo, contamos con un área especializada en el desarrollo de datos y utilizamos herramientas como CRM propio, Chatbot y Llamadores automatizados. Además, disponemos de una camioneta para el traslado del personal de ventas presenciales y oficinas físicas debidamente homologadas en Mendoza Capital.</p>
                <p>En The Best Connection S.A.S, nos enorgullecemos de la pasión y dedicación que ponemos en cada proyecto que emprendemos. Nuestro compromiso es continuar liderando en el mercado, impulsando la inclusión y contribuyendo al desarrollo sostenible de nuestro sector.</p>
            </div>
        </div>
    )
}